import { put } from "redux-saga/effects";
import axios from "axios";
import {
  SET_HOME_DATA,
  SHOW_TOAST,
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);
export function* getHomeDataSaga(params) {
    try {
        let getHomeDataResponse = yield axios.post(
            `${env.newBaseURLDev}/workspaces/action/get_homepage_action_tabs/`,
            {
                "board_id": JSON.parse(localStorage.getItem("new_board")).id,
                "uid": JSON.parse(localStorage.getItem("new_resource_owner")).uid,
                "filters": ["recently_updated", "assigned_to_me", "upcoming", "watching"]
            },
            {
                headers: {
                  "Content-Type": "application/json", 
                  "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            }
        );
        console.log("getHomeDataResponse", getHomeDataResponse);
        yield put({
            type: SET_HOME_DATA,
            payload: getHomeDataResponse.data,
          });
    } catch (error) {
        yield put({
            type: SHOW_TOAST,
            payload: true,
          });
    }   
}