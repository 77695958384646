import { put } from "redux-saga/effects";
import axios from "axios";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);
export function* getUserMetaSaga(params) {

    try {
        let getUserMetaResponse = yield axios.get(
            `${env.newBaseURLDev}/users/user-meta/get_user_meta`,
            {
                headers: {
                  "Content-Type": "application/json", 
                  "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            }
        );
        console.log("getUserMetaResponse", getUserMetaResponse);
        yield put({
            type: "SET_USER_META",
            payload: getUserMetaResponse.data,
          });
    } catch (error) {
        console.log("error", error);
    }   
}

export function* updateUserMetaSaga(params) {

    try {
        let updateUserMetaResponse = yield axios.post(
            `${env.newBaseURLDev}/users/user-meta/`,
            params.params,
            {
                headers: {
                  "Content-Type": "application/json", 
                  "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            }
        );
        console.log("updateUserMetaResponse", updateUserMetaResponse);
        yield put({
            type: "SET_USER_META",
            payload: updateUserMetaResponse.data,
          });
    } catch (error) {
        console.log("error", error);
    }   
}
export function* updateUserInfoSaga(params) {

    try {
        let updateUserMetaResponse = yield axios.post(
            `${env.newBaseURLDev}/users/user/update_user_info/`,
            params.params,
            {
                headers: {
                  "Content-Type": "application/json", 
                  "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
            }
        );
        yield put({
            type: "SET_USER_META",
            payload: updateUserMetaResponse.data,
          });
    } catch (error) {
        console.log("error", error);
    }   
}

