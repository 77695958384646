import {
  GET_BOARD_LANES,
  SET_BOARD_LANES,
} from "../actions/types";

const initialState = {
  allLanesLoading: false,
  allLanes: [],
  allAssignees: null,
};

export default function laneReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BOARD_LANES:
      return {
        ...state,
        allLanesLoading: true,
      };

    case SET_BOARD_LANES:
      return {
        ...state,
        allLanesLoading: false,
        allLanes: action.data,
      };

    default:
      return state;
  }
}
