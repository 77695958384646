import { GET_MARKETPLACE_DATA, SET_MARKETPLACE_DATA, SET_MARKETPLACE_DATA_LOADING } from "./types";

export const getMarketplaceDataAction = () => ({
    type: GET_MARKETPLACE_DATA,
});

export const setMarketplaceDataAction = data => ({
    type: SET_MARKETPLACE_DATA,
    params: data,
});

export const setMarketplaceDataLoadingAction = data => ({
    type: SET_MARKETPLACE_DATA_LOADING,
    params: data,
});

export const runAgentAction = data => ({
  type: "RUN_AGENT",
  params: data,
});

export const addAgentAction = data => ({
  type: "ADD_AGENT",
  params: data,
});

export const removeAgentAction = data => ({
  type: "REMOVE_AGENT",
  params: data,
});

export const getAgentJobDetailsAction = data => ({
  type: "GET_AGENT_JOB_DETAILS",
  params: data,
});

export const getAgentHistoryAction = data => ({
  type: "GET_AGENT_HISTORY",
  params: data,
});

export const getAddedAgentsAction = data => ({
  type: "GET_ADDED_AGENTS",
  params: data,
});
