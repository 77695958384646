import {
  GET_ACCESS_SITES,
  GET_ALL_SITES,
  SET_ACCESS_SITES,
  SET_ALL_SITES,
  SET_USERS_FOR_SEARCH,
  SET_WORKSPACE_MAN_HOURS,
  SET_DASHBOARD_COUNT,
  SET_COMBINED_SITES,
  GET_COMBINED_SITES,
  RESET_ALL_SITES,
  SET_SELECTED_WORKSPACE,
} from "../actions/types";

const initialState = {
  allSitesLoading: false,
  allSites: [],
  allAccessSitesLoading: false,
  allAccessSites: [],
  userOptions: [],
  workspaceManHours: null,
  dashboardCount: [],
  combinedSites: [],
  combinedSitesLoading: false,
  selectedWorkspace: null,
  selectedWorkspaceLoading: false,
};

export default function siteReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_SITES:
      return {
        ...state,
        allSitesLoading: true,
        selectedWorkspaceLoading: true, // Set loading state for selectedWorkspace
      };

    case SET_ALL_SITES:
      return {
        ...state,
        allSitesLoading: false,
        allSites: action.data,
        selectedWorkspaceLoading: false,
        selectedWorkspace: action.data.length > 0 ? action.data[0] : null, // Set initial selectedWorkspace
      };

    case SET_SELECTED_WORKSPACE:
      return {
        ...state,
        selectedWorkspaceLoading: false,
        selectedWorkspace: action.data,
      };

    case RESET_ALL_SITES:
      return {
        ...state,
        allSites: null,
        selectedWorkspace: null,
      };

    case GET_ACCESS_SITES:
      return {
        ...state,
        allAccessSitesLoading: false,
      };

    case SET_ACCESS_SITES:
      return {
        ...state,
        allAccessSitesLoading: false,
        allAccessSites: action.data,
      };

    case SET_USERS_FOR_SEARCH:
      return {
        ...state,
        userOptions: action.data,
      };

    case SET_WORKSPACE_MAN_HOURS:
      return {
        ...state,
        workspaceManHours: action.data,
      };

    case SET_DASHBOARD_COUNT:
      return {
        ...state,
        dashboardCount: action.data,
      };

    case GET_COMBINED_SITES:
      return {
        ...state,
        combinedSitesLoading: true,
      };

    case SET_COMBINED_SITES:
      return {
        ...state,
        combinedSitesLoading: false,
        combinedSites: action.data,
      };

    default:
      return state;
  }
}
