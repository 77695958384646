import { SET_BOARD_USERS } from "../actions/types";

const initialState = {
    boardUsers: [],
};
export default function settingReducer(state = initialState, action) {
  switch (action.type) {

    case SET_BOARD_USERS:
      return {
        ...state,
        boardUsers: action.payload,
      };

    default:
      return state;
  }
}
