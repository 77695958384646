import { put } from "redux-saga/effects";
import axios from "axios";
import {
  SET_ALL_NOTIFICATIONS,
  SHOW_TOAST,
  SET_ALL_UNREAD_NOTIFICATIONS_COUNT
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getAllNotificationsSaga(params) {
  try {
    if(params.params?.page === 1){
    yield put({
      type: "NOTIFICATIONS_LOADING",
        data: true,
      });
    }
    
    let getAllNotificationsResponse = yield axios.get(
      `${env.newBaseURLDev}/notifications/notification?page=${params.params.page}&page_size=20`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );
    if (getAllNotificationsResponse.status === 200) {
      yield put({
        type: SET_ALL_NOTIFICATIONS,
        data: getAllNotificationsResponse.data,
      });
      yield put({
        type: SET_ALL_UNREAD_NOTIFICATIONS_COUNT,
        data: getAllNotificationsResponse.data.unread_count,
      });
      yield put({
        type: "NOTIFICATIONS_LOADING",
        data: false,
      });
      if(params.params?.onSuccess){
        params.params.onSuccess(getAllNotificationsResponse.data);
      }
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* updateReadStatusSaga(params) {
  console.log(params, "params");
  try {
    let reqObj={};
    if (params.params === "all") {
      reqObj = {
        "mark_all": true
      }
    }else{
      reqObj = {
        "notification_id" : params.params.notification_id
      }
    }

    let updateReadStatusResponse = yield axios.put(
      `${env.newBaseURLDev}/notifications/notification/mark_notification_inactive/`,
      reqObj,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (updateReadStatusResponse.status === 200) {
      yield getAllNotificationsSaga();
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}
export function* allNotificationsReadSaga(params) {
  try {
    let reqObj = {
      "mark_all": true
    }
    yield axios.put(
      `${env.newBaseURLDev}/notifications/notification/mark_notification_inactive/`,
      reqObj,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

// export function* allNotificationsReadSaga() {
//   try {
//     let updateReadStatusResponse = yield axios.get(
//       `${env.baseURLDev}/notifications/update-all-read-status`,
//       {
//         headers: {
//           authorization: `${localStorage.getItem("token")}`,
//         },
//       }
//     );

//     if (updateReadStatusResponse.status === 200) {
//       // yield put({type: SET_ALL_NOTIFICATIONS, data: updateReadStatusResponse.data.data})
//     }
//   } catch {
//     yield put({
//       type: SHOW_TOAST,
//       data: {
//         message: "Something went wrong, please try again later.",
//         toastType: 0,
//       },
//     });
//   }
// }
