import { SET_WORKSPACE_ID,SET_ORG_ID,SET_BOARD_ID} from "../actions/types";

const initialState = {
    workspaceId: null,
    orgId: null,
    boardId: null,  
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {

    case SET_WORKSPACE_ID:
      return {
        ...state,
        workspaceId: action.payload,
      };

    case SET_ORG_ID:
      return {
        ...state,
        orgId: action.payload,
      };

    case SET_BOARD_ID:
      return {
        ...state,
        boardId: action.payload,
      };

    default:
      return state;
  }
}
