import { call } from 'redux-saga/effects';
const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);



export function* handleWorkspaceDetailsUpdateSaga(action) {
  try {
    const { workspaceName, actionKey, workspaceID, workspaceAvatar } = action.details;
    console.log(workspaceName, actionKey, workspaceID, workspaceAvatar)
    const postRequestForWorkspaceDetailsUpdate = yield call(fetch, `${env.newBaseURLDev}/workspaces/workspace/${workspaceID}/`, {
      method: "PUT",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        workspace_name: workspaceName,
        work_space_slug: actionKey,
        workspace_avatar: workspaceAvatar,
      }),
    });

    if (!postRequestForWorkspaceDetailsUpdate.ok) {
      throw new Error("Failed to update workspace details.");
    }

    // Update local storage
    const existingWorkspace = JSON.parse(localStorage.getItem("new_workspace"));
    const updatedWorkspace = {
      ...existingWorkspace,
      workspace_name: workspaceName,
      work_space_slug: actionKey,
      ...(workspaceAvatar && { workspace_avatar: workspaceAvatar }),
    };
    localStorage.setItem("new_workspace", JSON.stringify(updatedWorkspace));

  } catch (error) {
    console.error("Error updating workspace details:", error);
  }
}

export function* handleWorkspaceDeleteSaga(action) {
  try {
    const postRequestForWorkspaceDetailsUpdate = yield call(fetch, `${env.newBaseURLDev}/site/delete-workspace`, {
      method: "POST",
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        slug: action.workspaceID,
      }),
    });

    if (!postRequestForWorkspaceDetailsUpdate.ok) {
      throw new Error("Failed to delete workspace.");
    }

    // Redirect to home page
    window.location.href = "/";

  } catch (error) {
    console.error("Error deleting workspace.", error);
  }
}
