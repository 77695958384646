import { HIDE_LOADER, SHOW_LOADER } from "../actions/types";

const initialState = {
  display: false,
};

export default function loaderReducer(state = initialState, action) {
  switch (action.type) {
    case HIDE_LOADER:
      return {
        ...state,
        display: false,
      };

    case SHOW_LOADER:
      return {
        ...state,
        display: true,
      };

    default:
      return state;
  }
}
