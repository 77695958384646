const initialState = {
    userMeta: null,
};

export default function userMetaReducer(state = initialState, action) {
  switch (action.type) {

    case "SET_USER_META":
      return {
        ...state,
        userMeta: action.payload,
      };

    default:
      return state
  }
}
