import {
    SET_SPACES_DATA
  } from "../actions/types";
  
  const initialState = {
    spacesData: null,
    folderDocuments: null,
    spacesLoading: false,
    folderLoading: false,
    documentLoading: false,
  };
  
  export default function spacesReducer(state = initialState, action) {
    switch (action.type) {
      case SET_SPACES_DATA:
        return {
          ...state,
          spacesData: action.payload,
          spacesLoading: false,
        };
      case "SET_FOLDER_DOCUMENTS":
        return {
          ...state,
          folderDocuments: action.payload,
          folderLoading: false,
        };
      case "SET_ALL_FOLDER_DOCUMENTS":
        return {
          ...state,
          allFolderDocuments: action.payload,
        };
      case "SET_SPACES_LOADING":
        return {
          ...state,
          spacesLoading: action.payload,
        };
      case "SET_FOLDER_LOADING":
        return {
          ...state,
          folderLoading: action.payload,
        };
      case "SET_DOCUMENT_LOADING":
        return {
          ...state,
          documentLoading: action.payload,
        };
      case "CREATE_SPACES_ENTITY":
        return state;
      default:
        return state;
    }
  }
