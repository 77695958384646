import { GET_SPACES_DATA, SET_SPACES_DATA } from "./types";

export const getSpacesDataAction = () => ({
    type: GET_SPACES_DATA,
});

export const setSpacesDataAction = data => ({
    type: SET_SPACES_DATA,
    params: data,
});

export const createSpacesEntityAction = data => ({
    type: "CREATE_SPACES_ENTITY",
    params: data,
});

export const getFolderDocumentsAction = data => ({
    type: "GET_FOLDER_DOCUMENTS",
    params: data,
});

export const setFolderDocumentsAction = data => ({
    type: "SET_FOLDER_DOCUMENTS",
    payload: data,
});

export const getAllFolderDocumentsAction = data => ({
    type: "GET_ALL_FOLDER_DOCUMENTS",
    params: data,
});

export const setAllFolderDocumentsAction = data => ({
    type: "SET_ALL_FOLDER_DOCUMENTS",
    payload: data,
});

export const renameSpacesEntityAction = data => ({
    type: "RENAME_SPACES_ENTITY",
    params: data,
});

export const deleteSpacesEntityAction = data => ({
    type: "DELETE_SPACES_ENTITY",
    params: data,
});

export const updateDocumentEntityAction = data => ({
    type: "UPDATE_DOCUMENT_ENTITY",
    params: data,
});