import { SET_HOME_DATA } from "../actions/types";

const initialState = {
    homeData: null,
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {

    case SET_HOME_DATA:
      return {
        ...state,
        homeData: action.payload,
      };

    default:
      return state
  }
}
