import { createContext, useContext, useEffect, useState } from "react";

const ThemeContext = createContext();

const getSystemTheme = () => {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    return "dark";
  } else {
    return "light";
  }
};

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() =>
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
  );

  const toggleTheme = (newTheme) => {
    const themeToApply = newTheme === "system" ? getSystemTheme() : newTheme;
    setTheme(newTheme);
    themeToApply === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  useEffect(() => {
    
    
    let themeToApply = theme;
    if (theme === "Match browser settings"){
      themeToApply = "system"
    }else if (theme === "Light Mode"){
      themeToApply = "light"
    }else if (theme === "Dark Mode"){
      themeToApply = "dark"
    }
    // console.log("theme", theme,themeToApply);
    toggleTheme(themeToApply);
    localStorage.setItem("theme", themeToApply);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

export const useTheme = () => {
  const context = useContext(ThemeContext);
  console.log("context",ThemeContext, context);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
