import {
  POST_MAN_HOURS,
  SET_MAN_HOURS,
  ERROR_MAN_HOURS,
  RESET_MAN_HOURS,
} from "../actions/types";

const initialState = {
  manHoursLoading: false,
  manHoursResponse: null,
};

export default function manHoursReducer(state = initialState, action) {
  switch (action.type) {
    case POST_MAN_HOURS:
      return {
        ...state,
        manHoursLoading: true,
        manHoursResponse: null,
      };

    case SET_MAN_HOURS:
      return {
        ...state,
        manHoursLoading: false,
        manHoursResponse: action.data,
      };
    case ERROR_MAN_HOURS:
      return {
        ...state,
        manHoursLoading: false,
      };

    case RESET_MAN_HOURS:
      return {
        ...state,
        manHoursLoading: false,
        manHoursResponse: null,
      };

    default:
      return state;
  }
}
