import axios from "axios";
import { put, call } from "redux-saga/effects";
import { SHOW_TOAST,SET_ALL_BOARD_ENTITY,SET_BOARD_MEMBERS} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

const { pathname } = window.location;

export function* addNewBoardSaga(params) {
  try {
    let addNewBoardResponse = yield axios.post(
      `${env.baseURLDev}/board/create`,
      params.params,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );
    if (addNewBoardResponse.status === 201) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Your board has been added.", toastType: 1 },
      });
      setTimeout(() => {
        window.location = `${pathname}?site=${params.params.currentSite.slug}&board=${addNewBoardResponse.data.data}`;
      }, 5000);
    }
  } catch (e) {
    if (e.message.includes("404")) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Selected site no longer available.", toastType: 0 },
      });
    }

    if (e.message.includes("400")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Board already exists, please use another name.",
          toastType: 0,
        },
      });
    }

    if (e.message.includes("401")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Only one board can be created for this site.",
          toastType: 0,
        },
      });
    }

    if (e.message.includes("402")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "As a viewer you cannot create a board for this site.",
          toastType: 0,
        },
      });
    }

    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}

// export function* getAllBoardsSaga(params) {
//   try {
//     let getAllBoardsResponse = yield axios.get(
//       `${env.baseURLDev}/board/get-all?slug=${params.params.site}`,
//       {
//         headers: {
//           authorization: `${localStorage.getItem("token")}`,
//         },
//       }
//     );

//     yield put({ type: SET_ALL_BOARDS, data: getAllBoardsResponse.data.data });
//   } catch (e) {
//     if (e.message.includes("404")) {
//       window.location = "/no-access";
//     }
//   }
// }

export function* redirectToBoardSaga(params) {
  try {
    let redirectToBoardResponse = yield axios.post(
      `${env.baseURLDev}/board/get-details`,
      { slug: params.params },
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    // console.log(redirectToBoardResponse.data.data.name);
    if (redirectToBoardResponse.status === 200) {
      if (redirectToBoardResponse.data.data.length > 0) {
        window.location = `/tasks?site=${params.params}&board=${redirectToBoardResponse.data.data[0].slug}`;
      } else {
        window.location = `/tasks?site=${params.params}`;
      }
    }
  } catch (e) {
    // if (e.message.includes('404')) {
    //     window.location = "/no-access";
    // }
  }
}
//get_all_board_entity
export function* getAllBoardEntitySaga(params) {
  console.log("getAllBoardEntitySaga", params);
  try {
    let getAllBoardEntityResponse = yield axios.get(
      `${env.newBaseURLDev}/workspaces/board_meta/get_all_board_entity/?board_id=${JSON.parse(localStorage.getItem("new_board")).id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (getAllBoardEntityResponse.status === 200) {
      yield put({
        type: SET_ALL_BOARD_ENTITY,
        data: getAllBoardEntityResponse.data,
      });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}
export function* getBoardMembersSaga(params) {
  try {
    let getBoardMembersResponse = yield axios.get(
      `${env.newBaseURLDev}/workspaces/board_mapping/get_board_members/?board_id=${params.params}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (getBoardMembersResponse.status === 200) {
      yield put({
        type: SET_BOARD_MEMBERS,
        data: getBoardMembersResponse.data,
      });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* customiseBoardEntitySaga(params) {
  try {
    let customiseBoardEntityResponse = yield axios.post(
      `${env.newBaseURLDev}/workspaces/board_meta/update_board_meta/`,
      params.params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (customiseBoardEntityResponse.status === 200 || customiseBoardEntityResponse.status === 201) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Board entity customised successfully.",
          toastType: 1,
        },
      });
      yield call(getAllBoardEntitySaga);
      if(params.params.onSuccess){
        params.params.onSuccess(customiseBoardEntityResponse.data);
      }
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
    if(params.params.onSuccess){
      params.params.onSuccess();
    }
  }
}

export function* deleteBoardEntitySaga(params) {
  try {
    let deleteBoardEntityResponse = yield axios.post(
      `${env.newBaseURLDev}/workspaces/board_meta/delete_choice/`,
      params.params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (deleteBoardEntityResponse.status === 200 || deleteBoardEntityResponse.status === 201  || deleteBoardEntityResponse.status === 400) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Board entity deleted successfully.",
          toastType: 1,
        },
      });
      yield call(getAllBoardEntitySaga);
      if(params.params.onSuccess){
        params.params.onSuccess(deleteBoardEntityResponse.data);
      }
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
    if(params.params.onSuccess){
      params.params.onSuccess();
    }
  }
}



export function* createNewIssuePrioritySaga(params) {
  console.log("Create New Issue Type Saga called");
  try {
    const createNewIssueResponse = yield call(
      axios.post,
      `${env.newBaseURLDev}/workspaces/board_meta/create_priority_types/`,
      params.params,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `${localStorage.getItem("new_token")}`,
        },
      }
    );

    if (createNewIssueResponse.status === 200) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Issue created successfully.", toastType: 1 },
      });
      yield call(getAllBoardEntitySaga);
      if(params.params.onSuccess){
        params.params.onSuccess(createNewIssueResponse.data);
      }
    }
  } catch (e) {
    console.error("Error creating new issue type:", e);
    const errorMessage =
      e.response?.status === 400
        ? "Issue already created for this workspace."
        : "Something went wrong, please try again later.";
        
    yield put({
      type: SHOW_TOAST,
      data: {
        message: errorMessage,
        toastType: 0,
      },
    });
  }
}

export function* createNewIssueStatusSaga(params) {
  console.log("Create New Issue Type Saga called");
  try {
    const createNewIssueResponse = yield call(
      axios.post,
      `${env.newBaseURLDev}/workspaces/board_meta/create_status_types/`,
      params.params,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `${localStorage.getItem("new_token")}`,
        },
      }
    );

    if (createNewIssueResponse.status === 200) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Status created successfully.", toastType: 1 },
      });
      yield call(getAllBoardEntitySaga);
      if(params.params.onSuccess){
        params.params.onSuccess(createNewIssueResponse.data);
      }
    }
  } catch (e) {
    console.error("Error creating new issue type:", e);
    const errorMessage =
      e.response?.status === 400
        ? "Issue already created for this workspace."
        : "Something went wrong, please try again later.";
        
    yield put({
      type: SHOW_TOAST,
      data: {
        message: errorMessage,
        toastType: 0,
      },
    });
  }
}


export function* reorderBoardEntitySaga(params) {
  try {
    let reorderBoardEntityResponse = yield axios.post(
      `${env.newBaseURLDev}/workspaces/board_meta/update_choices/`,
      params.params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (reorderBoardEntityResponse.status === 200 || reorderBoardEntityResponse.status === 201  || reorderBoardEntityResponse.status === 400) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Board entity reordered successfully.",
          toastType: 1,
        },
      });
      yield call(getAllBoardEntitySaga);
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* removeBoardMemberSaga(params) {
  try {
    let removeBoardMemberResponse = yield axios.post(
      `${env.newBaseURLDev}/workspaces/board_mapping/remove_board_member/`,
      params.params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (removeBoardMemberResponse.status === 200 || removeBoardMemberResponse.status === 201) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Board member removed successfully.",
          toastType: 1,
        },
      });
      yield call(getBoardMembersSaga, {params : params.params.board_id});
      if(params.params.onSuccess){
        params.params.onSuccess(removeBoardMemberResponse.data);
      }
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
    if(params.params.onSuccess){
      params.params.onSuccess();
    }
  }
}

export function* getBoardMembersRolesSaga(params) {
  try {
    let getBoardMembersRolesResponse = yield axios.get(
      `${env.newBaseURLDev}/roles`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (getBoardMembersRolesResponse.status === 200) {
      yield put({
        type: "SET_BOARD_MEMBERS_ROLES",
        data: getBoardMembersRolesResponse.data,
      });
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.href = "/log-in";
    } else {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}

export function* updateBoardMembersRolesSaga(params) {
  let requestParams = {
    workspace_id: JSON.parse(localStorage.getItem("new_workspace")).id,
    org_id: JSON.parse(localStorage.getItem("new_org_details")).id,
    role_id: params.params.role_id,
    uid: params.params.uid
  }
  try {
    let updateBoardMembersRolesResponse = yield axios.post(
      `${env.newBaseURLDev}/roles/update_role/`,
      requestParams,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (updateBoardMembersRolesResponse.status === 200) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Board members roles updated successfully.",
          toastType: 1,
        },
      });
      yield call(getBoardMembersSaga, {params : JSON.parse(localStorage.getItem("new_board")).id});
      if(params.params.onSuccess){
        params.params.onSuccess(updateBoardMembersRolesResponse.data);
      }
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}