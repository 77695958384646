import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({ title, description, name, type }) {
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:url" content="https://permi-tech-space.s3.amazonaws.com/5c28596f-9791-4fa0-b9ff-53b7e01170b2/38c1019b-1519-4939-a52d-51fecbe4f36a-Colour=Filled.png" />
            <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content="https://permi-tech-space.s3.amazonaws.com/5c28596f-9791-4fa0-b9ff-53b7e01170b2/38c1019b-1519-4939-a52d-51fecbe4f36a-Colour=Filled.png"/>
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta property="twitter:image" content="https://permi-tech-space.s3.amazonaws.com/5c28596f-9791-4fa0-b9ff-53b7e01170b2/38c1019b-1519-4939-a52d-51fecbe4f36a-Colour=Filled.png"/>
            { /* End Twitter tags */}
            { /* Slack tags */}
            <meta property="slack:creator" content={name} />
            <meta property="slack:title" content={title} />
            <meta property="slack:description" content={description} />
            <meta property="slack:image" content="https://permi-tech-space.s3.amazonaws.com/5c28596f-9791-4fa0-b9ff-53b7e01170b2/38c1019b-1519-4939-a52d-51fecbe4f36a-Colour=Filled.png"/>
            { /* End Slack tags */}
            { /* WhatsApp tags */}
            <meta property="whatsapp:title" content={title} />
            <meta property="whatsapp:description" content={description} />
            <meta property="whatsapp:image" content="https://permi-tech-space.s3.amazonaws.com/5c28596f-9791-4fa0-b9ff-53b7e01170b2/38c1019b-1519-4939-a52d-51fecbe4f36a-Colour=Filled.png"/>
            { /* End WhatsApp tags */}
            { /* LinkedIn tags */}
            <meta property="linkedin:title" content={title} />
            <meta property="linkedin:description" content={description} />
            <meta property="linkedin:image" content="https://permi-tech-space.s3.amazonaws.com/5c28596f-9791-4fa0-b9ff-53b7e01170b2/38c1019b-1519-4939-a52d-51fecbe4f36a-Colour=Filled.png"/>
            { /* End LinkedIn tags */}
        </Helmet>
    )
}