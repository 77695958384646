import { GET_ALL_BOARDS, SET_ALL_BOARDS, SET_ALL_BOARD_ENTITY,SET_BOARD_MEMBERS} from "../actions/types";

const initialState = {
  allBoardsLoading: false,
  allBoards: [],
  boardMembers: [],
  allBoardEntity:{},
  boardMembersRoles: []
};

export default function boardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BOARDS:
      return {
        ...state,
        allBoardsLoading: true,
      };

    case SET_ALL_BOARDS:
      return {
        ...state,
        allBoardsLoading: false,
        allBoards: action.data,
      };

    case SET_ALL_BOARD_ENTITY:
      return {
        ...state,
        allBoardEntity: action.data,
      };

    case SET_BOARD_MEMBERS:
      return {
        ...state,
        boardMembers: action.data,
      };  

    case "SET_BOARD_MEMBERS_ROLES":
      return {
        ...state,
        boardMembersRoles: action.data,
      };
    default:
      return state;
  }
}
