import { SHOW_SEARCH ,SET_SEARCH_RESULTS,SEARCH_LOADED} from "../actions/types";

const initialState = {
  showSearch: false,
  searchResults: [],
  searchLoaded: false,
};

export default function searchReducer(state = initialState, action) {
  switch (action.type) {

    case SHOW_SEARCH:
      return {
        ...state,
        showSearch: action.payload,
      };

    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    case SEARCH_LOADED:
      return {
        ...state,
        searchLoaded: action.payload,
      };

    default:
      return state;
  }
}
