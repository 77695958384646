import { put } from "redux-saga/effects";
import axios from "axios";
import {
  SHOW_TOAST,
  SET_MARKETPLACE_DATA,
  SET_MARKETPLACE_DATA_LOADING
} from "../actions/types";


const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getMarketplaceDataSaga(params) {
    try {
        yield put({
            type: SET_MARKETPLACE_DATA_LOADING,
            payload: true,
          });
        let getMarketplaceDataResponse = yield axios.get(
            `${env.newBaseURLDev}/templates/template/`,{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
              }
        );

        let getAgentsDataResponse = yield axios.get(
            `${env.newBaseURLDev}/agent/agents/get_paginated_agents/`,{
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
              }
        );
        yield put({
            type: SET_MARKETPLACE_DATA,
            payload: {
                templates: getMarketplaceDataResponse.data?.templates,
                agents: getAgentsDataResponse?.data?.agents
            },
          });
        yield put({
            type: SET_MARKETPLACE_DATA_LOADING,
            payload: false,
          });
    } catch (error) {
        yield put({
            type: SHOW_TOAST,
            payload: true,
          });
        yield put({
            type: SET_MARKETPLACE_DATA_LOADING,
            payload: false,
          });
    }   
}

export function* getAddedAgentsSaga(params) {
  try {
      let getAddedAgentsResponse = yield axios.get(
          `${env.newBaseURLDev}/agent/agents/get_paginated_agents/?fetch_all=false`,{
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }
            }
      );
      yield put({
          type: "SET_ADDED_AGENTS",
          payload: getAddedAgentsResponse?.data
        });

  } catch (error) {

  }   
}

export function* runAgentSaga(params) {
  try {
      let runAgentResponse = yield axios.post(
          `${env.newBaseURLDev}/agent/agents/schedule_job/`,
          {
            agent_id: params.params.agent_id,
            workspace_id: params.params.workspace_id,
            meta: params.params.meta,
            task_name: params.params.task_name,
          },
          {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }
            }
      );
      if(runAgentResponse.status === 200 || runAgentResponse.status === 201) {
        params.params.onSuccess(runAgentResponse?.data);
      }else{
      }

  } catch (error) {
  }   
}


export function* addAgentSaga(params) {
  try {
      let addAgentResponse = yield axios.post(
          `${env.newBaseURLDev}/agent/agents/add_agent/`,
          {
            agent_id: params.params.agent_id,
            workspace_id: params.params.workspace_id,
          },
          {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }
            }
      );
      if(addAgentResponse.status === 200) {
        params.params.onSuccess(addAgentResponse?.data);
      }else{
      }
      
  } catch (error) {
  }   
}

export function* removeAgentSaga(params) {
  try {
      let removeAgentResponse = yield axios.post(
          `${env.newBaseURLDev}/agent/agents/remove_agent/`,
          {
            agent_id: params.params.agent_id,
            workspace_id: params.params.workspace_id,
          },
          {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }
            }
      );
      if(removeAgentResponse.status === 200) {
          params.params.onSuccess(removeAgentResponse?.data);
      }else{
      }
      
  } catch (error) {
  }   
}

export function* getAgentJobDetailsSaga(params) {
  try {
      let getAgentJobDetailsResponse = yield axios.get(
          `${env.newBaseURLDev}/agent/agents/get_job_detail/?job_id=${params.params.job_id}`,
          {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }
            }
      );
      if(getAgentJobDetailsResponse.status === 200) {
        params.params.onSuccess(getAgentJobDetailsResponse?.data);
      }else{
      }
      
  } catch (error) {
  }   
}


export function* getAgentHistorySaga(params) {
  try {
    console.log(params.params);
      let getAgentHistoryResponse = yield axios.get(
          `${env.newBaseURLDev}/agent/agents/get_paginated_history/?workspace_id=${JSON.parse(localStorage.getItem("new_workspace"))?.id}&page=${params.params.page}`,{
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }
            }
      );
      yield put({
          type: "SET_AGENT_HISTORY",
          payload: getAgentHistoryResponse?.data,
        });
      if(params.params.onSuccess) {
        console.log(getAgentHistoryResponse?.data);
        params.params.onSuccess(getAgentHistoryResponse?.data);
      }
  } catch (error) {
  }   
}