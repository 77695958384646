import {
    SET_HOME_DATA,
    GET_HOME_DATA,
  } from "../actions/types";
  
 export const setHomeDataAction = (data) => ({
    type: SET_HOME_DATA,
    payload: data,
  });
  
  export const getHomeDataAction = (data) => ({
    type: GET_HOME_DATA,
    payload: data,
  });
    