import axios from "axios";


const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* gettextFromDocSaga(params) {
    try {
        let gettextFromDocResponse = yield axios.post(
            `${env.newBaseURLDev}/media/pdf-extract/extract_text/`,
            params.params.file,
            {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                
              },
              
        );
        console.log(gettextFromDocResponse);
        if (gettextFromDocResponse.status === 200) {
            params.params.onSuccess(gettextFromDocResponse.data);
        }

    } catch (error) {
        
    }
}


export function* getTextToSpaceSaga(params) {

    try {
        let gettextToSpaceResponse = yield axios.post(
            `${env.newBaseURLDev}/spaces/text_to_space/`,
            params.params,
            {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                
              },
              
        );
        if (gettextToSpaceResponse.status === 200 && params.params.onSuccess) {
            params.params.onSuccess(gettextToSpaceResponse.data);
        }

    } catch (error) {
        
    }
}

export function* updateEditAccessSaga(params) {
    try {
        let updateEditAccessResponse = yield axios.post(
            `${env.newBaseURLDev}/spaces/access/edit/`,
            {
                document_id: params.params.document_id,
                session : params.params.session,
            },
            {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }  
            },
              
        );
        if (updateEditAccessResponse.status === 200) {
            params.params.onSuccess(updateEditAccessResponse.data);
        }

    } catch (error) { 
    }
}




