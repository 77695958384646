import axios from "axios";
import { put } from "redux-saga/effects";
import { SET_KPI_METRIC, SHOW_TOAST } from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* geteKPIMetricSaga(params) {
  try {
    let getKPIMetricResponse = yield axios.get(
      `${env.baseURLDev}/dashboard/get-kpi-metric?workspace=${params.params.workspace}&kpi_id=${params.params.kpi_id}&start=${params.params.startDate}&end=${params.params.endDate}`
    );
    if (getKPIMetricResponse.status === 200) {
      yield put({ type: SET_KPI_METRIC, data: getKPIMetricResponse.data.data });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* archiveCardSaga(params) {
  try {
    let archiveCardResponse = yield axios.get(
      `${env.baseURLDev}/dashboard/archive-card?id=${params.params.id}`,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (archiveCardResponse.status === 200) {
      // yield put({ type: CARD_REMOVED, data: { id: params.params.id }});
      yield put({
        type: SHOW_TOAST,
        data: { message: "Card removed from dashboard.", toastType: 1 },
      });
      setTimeout(
        () => (window.location = `/dashboard?slug=${params.params.slug}`),
        1000
      );
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}
