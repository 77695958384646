import { GET_ALL_NOTIFICATIONS, UPDATE_READ_STATUS, ALL_NOTIFICATIONS_READ } from "./types";

export const getAllNotificationsAction = (params) => ({
    type: GET_ALL_NOTIFICATIONS,
    params: params,
});

export const updateReadStatusAction = data => ({
    type: UPDATE_READ_STATUS,
    params: data,
});

export const allNotificationsReadAction = () => ({
    type: ALL_NOTIFICATIONS_READ,
});