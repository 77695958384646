import { SHOW_SEARCH ,SET_SEARCH_RESULTS,SEARCH_LOADED,GET_SEARCH_RESULTS} from "./types";

export const getSearchResults = (data) => ({
    type: GET_SEARCH_RESULTS,
    payload: data,
});

export const toggleSearch = (data) => ({
    type: SHOW_SEARCH,
    payload: data,
});

export const setSearchResults = (results) => ({
    type: SET_SEARCH_RESULTS,
    payload: results,
}); 

export const setSearchLoaded = (loaded) => ({
    type: SEARCH_LOADED,
    payload: loaded,
}); 
    