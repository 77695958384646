import { SET_KPI_METRIC } from "../actions/types";

const initialState = {};

export default function kpiReducer(state = initialState, action) {
  switch (action.type) {
    case SET_KPI_METRIC:
      return {
        ...state,
      };

    default:
      return state;
  }
}
