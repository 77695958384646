export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

export const ADD_TOAST = "SHOW_TOAST";
export const REMOVE_TOAST = "HIDE_TOAST";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const POST_MAN_HOURS = "POST_MAN_HOURS";
export const SET_MAN_HOURS = "SET_MAN_HOURS";
export const ERROR_MAN_HOURS = "ERROR_MAN_HOURS";
export const RESET_MAN_HOURS = "RESET_MAN_HOURS";

export const SIGN_UP = "SIGN_UP";
export const LOG_IN = "LOG_IN";
export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_AVATAR = "UPDATE_AVATAR";
export const UPDATE_PROFILE_DETAILS = "UPDATE_PROFILE_DETAILS";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";

export const GET_USERS_FOR_SITES = "GET_USERS_FOR_SITES";
export const SET_USERS_FOR_SITES = "SET_USERS_FOR_SITES";

export const REVOKE_ACCESS_TO_SITE = "REVOKE_ACCESS_TO_SITE";

export const SET_DRAWER = "SET_DRAWER";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const OPEN_ACTIVITY_DRAWER = "OPEN_ACTIVITY_DRAWER";
export const CLOSE_ACTIVITY_DRAWER = "CLOSE_ACTIVITY_DRAWER";
export const OPEN_CONTEXT_MENU_DRAWER = "OPEN_CONTEXT_MENU_DRAWER";
export const CLOSE_CONTEXT_MENU_DRAWER = "CLOSE_CONTEXT_MENU_DRAWER";

export const SHOW_NEW_SITE_MODAL = "SHOW_NEW_SITE_MODAL";
export const HIDE_NEW_SITE_MODAL = "HIDE_NEW_SITE_MODAL";

export const ADD_NEW_SITE = "ADD_NEW_SITE";

export const ADD_NEW_BOARD = "ADD_NEW_BOARD";

export const ADD_USER_TO_PROJECT = "ADD_USER_TO_PROJECT";

export const GET_USERS_FOR_SEARCH = "GET_USERS_FOR_SEARCH";
export const SET_USERS_FOR_SEARCH = "SET_USERS_FOR_SEARCH";

export const LEAVE_SITE = "LEAVE_SITE";

export const UPDATE_SITE_AVATAR = "UPDATE_SITE_AVATAR";

export const UPDATE_SITE_DETAILS = "UPDATE_SITE_DETAILS";

export const UPDATE_MAN_HOURS = "UPDATE_MAN_HOURS";

export const GET_WORKSPACE_MAN_HOURS = "GET_WORKSPACE_MAN_HOURS";
export const SET_WORKSPACE_MAN_HOURS = "SET_WORKSPACE_MAN_HOURS";

export const SAVE_MAN_HOURS = "SAVE_MAN_HOURS";

export const GET_ALL_SITES = "GET_ALL_SITES";
export const SET_ALL_SITES = "SET_ALL_SITES";
export const RESET_ALL_SITES = "RESET_ALL_SITES";

export const GET_ACCESS_SITES = "GET_ACCESS_SITES";
export const SET_ACCESS_SITES = "SET_ACCESS_SITES";

export const SHOW_NEW_TASK_MODAL = "SHOW_NEW_TASK_MODAL";
export const HIDE_NEW_TASK_MODAL = "HIDE_NEW_TASK_MODAL";

export const SHOW_NEW_BOARD_MODAL = "SHOW_NEW_BOARD_MODAL";
export const HIDE_NEW_BOARD_MODAL = "HIDE_NEW_BOARD_MODAL";

export const SHOW_INVITE_USER_MODAL = "SHOW_INVITE_USER_MODAL";
export const HIDE_INVITE_USER_MODAL = "HIDE_INVITE_USER_MODAL";

export const SHOW_LEAVE_SITE_MODAL = "SHOW_LEAVE_SITE_MODAL";
export const HIDE_LEAVE_SITE_MODAL = "HIDE_LEAVE_SITE_MODAL";

export const SHOW_DELETE_TASK_MODAL = "SHOW_DELETE_TASK_MODAL";
export const HIDE_DELETE_TASK_MODAL = "HIDE_DELETE_TASK_MODAL";

export const SHOW_WORK_LOG_MODAL = "SHOW_WORK_LOG_MODAL";
export const HIDE_WORK_LOG_MODAL = "HIDE_WORK_LOG_MODAL";

export const SHOW_NEW_DASHBOARD_MODAL = "SHOW_NEW_DASHBOARD_MODAL";
export const HIDE_NEW_DASHBOARD_MODAL = "HIDE_NEW_DASHBOARD_MODAL";

export const SHOW_DELETE_WORKSPACE_MODAL = "SHOW_DELETE_WORKSPACE_MODAL";
export const HIDE_DELETE_WORKSPACE_MODAL = "HIDE_DELETE_WORKSPACE_MODAL";

export const SHOW_MAN_HOURS_MODAL = "SHOW_MAN_HOURS_MODAL";
export const HIDE_MAN_HOURS_MODAL = "HIDE_MAN_HOURS_MODAL";

export const SHOW_NEW_ISSUE_MODAL = "SHOW_NEW_ISSUE_MODAL";
export const HIDE_NEW_ISSUE_MODAL = "HIDE_NEW_ISSUE_MODAL";

export const DELETE_WORKSPACE = "DELETE_WORKSPACE";

export const SHOW_NEW_KPI_CARD = "SHOW_NEW_KPI_CARD";
export const HIDE_NEW_KPI_CARD = "HIDE_NEW_KPI_CARD";

export const GET_ALL_BOARDS = "GET_ALL_BOARDS";
export const SET_ALL_BOARDS = "SET_ALL_BOARDS";

export const REDIRECT_TO_BOARD = "REDIRECT_TO_BOARD";

export const GET_BOARD_LANES = "GET_BOARD_LANES";
export const SET_BOARD_LANES = "SET_BOARD_LANES";

export const GET_ISSUE_TYPES = "GET_ISSUE_TYPES";
export const SET_ISSUE_TYPES = "SET_ISSUE_TYPES";

export const CREATE_NEW_ISSUE = "CREATE_NEW_ISSUE";

export const ARCHIVE_ISSUE_TYPE = "ARCHIVE_ISSUE_TYPE";

export const CREATE_NEW_TASK = "CREATE_NEW_TASK";
export const CREATE_NEW_TASK_SUCCESS = "CREATE_NEW_TASK_SUCCESS";
export const CREATE_NEW_TASK_FAILURE = "CREATE_NEW_TASK_FAILURE";

export const SET_LANE_TICKETS = "SET_LANE_TICKETS";

export const SET_TICKET_DETAILS = "SET_TICKET_DETAILS";

export const SET_TICKET_WORK_LOG = "SET_TICKET_WORK_LOG";

export const SET_TICKET_MULTIMEDIA = "SET_TICKET_MULTIMEDIA";

export const SET_TICKET_ACTIVITY = "SET_TICKET_ACTIVITY";

export const POST_COMMENT = "POST_COMMENT";
export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const SET_ALL_COMMENTS = "SET_ALL_COMMENTS";

export const UPDATE_PRIORITY = "UPDATE_PRIORITY";
export const CHANGES_SAVED = "CHANGES_SAVED";
export const UPDATE_ASSIGNEE = "UPDATE_ASSIGNEE";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";

export const DELETE_TASK = "DELETE_TASK";
export const SAVE_TIME_LOG = "SAVE_TIME_LOG";

export const GET_SITE_MEMBERS = "GET_SITE_MEMBERS";
export const SET_SITE_MEMBERS = "SET_SITE_MEMBERS";

export const GET_SITE_OWNER = "GET_SITE_OWNER";
export const SET_SITE_OWNER = "SET_SITE_OWNER";

export const GET_TICKET_REPORTER = "GET_TICKET_REPORTER";
export const SET_TICKET_REPORTER = "SET_TICKET_REPORTER";

export const UPDATE_OWNER_FILTER = "UPDATE_OWNER_FILTER";

export const UPDATE_CURRENT_WORKSPACE = "UPDATE_CURRENT_WORKSPACE";

export const CREATE_NEW_DASHBOARD = "CREATE_NEW_DASHBOARD";

export const UPDATE_DASHBOARD_NAME_DESCRIPTION =
  "UPDATE_DASHBOARD_NAME_DESCRIPTION";

export const DUPLICATE_THIS_DASHBOARD = "DUPLICATE_THIS_DASHBOARD";

export const MOVE_TO_TRASH = "MOVE_TO_TRASH";

export const UPDATE_VIEWERS = "UPDATE_VIEWERS";

export const GET_SHARE_KEY = "GET_SHARE_KEY";
export const SET_SHARE_KEY = "SET_SHARE_KEY";
export const SHARE_KEY_LOADING = "SHARE_KEY_LOADING";
export const CONFIRM_SHARE_KEY_LOADING = "CONFIRM_SHARE_KEY_LOADING";

export const CONFIRM_SHARE = "CONFIRM_SHARE";

export const GET_ALL_DASHBOARDS = "GET_ALL_DASHBOARDS";
export const SET_ALL_DASHBOARDS = "SET_ALL_DASHBOARDS";

export const GET_ALL_ACCESS_DASHBOARDS = "GET_ALL_ACCESS_DASHBOARDS";
export const SET_ALL_ACCESS_DASHBOARDS = "SET_ALL_ACCESS_DASHBOARDS";

export const GET_ALL_CARDS = "GET_ALL_CARDS";
export const SET_ALL_CARDS = "SET_ALL_CARDS";

export const GET_ALL_VIEW_CARDS = "GET_ALL_VIEW_CARDS";
export const SET_ALL_VIEW_CARDS = "SET_ALL_VIEW_CARDS";

export const ADD_CARD_TO_BOARD = "ADD_CARD_TO_BOARD";

export const GET_KPI_METRIC = "GET_KPI_METRIC";
export const SET_KPI_METRIC = "SET_KPI_METRIC";

export const ARCHIVE_CARD = "ARCHIVE_CARD";
export const CARD_REMOVED = "CARD_REMOVED";

export const CALL_REFRESH = "CALL_REFRESH";
export const REFRESH_COMPLETE = "REFRESH_COMPLETE";

export const SELECT_FILTERED_WORKSPACE_MOBILE =
  "SELECT_FILTERED_WORKSPACE_MOBILE";

export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const SET_ALL_NOTIFICATIONS = "SET_ALL_NOTIFICATIONS";
export const SET_ALL_UNREAD_NOTIFICATIONS_COUNT =
  "SET_ALL_UNREAD_NOTIFICATIONS_COUNT";

export const UPDATE_READ_STATUS = "UPDATE_READ_STATUS";

export const ALL_NOTIFICATIONS_READ = "ALL_NOTIFICATIONS_READ";

export const GET_DASHBOARD_COUNT = "GET_DASHBOARD_COUNT";
export const SET_DASHBOARD_COUNT = "SET_DASHBOARD_COUNT";

export const GET_TASKS = "GET_TASKS";
export const SET_TASKS = "SET_TASKS";
export const RESET_TASKS = "RESET_TASKS";

export const GET_COMBINED_SITES = "GET_COMBINED_SITES";
export const SET_COMBINED_SITES = "SET_COMBINED_SITES";

export const SHOW_SEARCH = "SHOW_SEARCH";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SEARCH_LOADED = "SEARCH_LOADED";
export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";

export const SET_WORKSPACE_ID = "SET_WORKSPACE_ID";
export const SET_ORG_ID = "SET_ORG_ID";
export const SET_BOARD_ID = "SET_BOARD_ID";

export const SET_BOARD_USERS = "SET_BOARD_USERS";

export const SET_HOME_DATA = "SET_HOME_DATA";
export const GET_HOME_DATA = "GET_HOME_DATA";

export const GET_SELECTED_WORKSPACE = "GET_SELECTED_WORKSPACE";
export const SET_SELECTED_WORKSPACE = "SET_SELECTED_WORKSPACE";

export const SET_ALL_BOARD_ENTITY = "SET_ALL_BOARD_ENTITY";
export const GET_ALL_BOARD_ENTITY = "GET_ALL_BOARD_ENTITY";

export const SET_BOARD_MEMBERS = "SET_BOARD_MEMBERS";
export const GET_BOARD_MEMBERS = "GET_BOARD_MEMBERS";

export const SET_SPACES_DATA = "SET_SPACES_DATA";
export const GET_SPACES_DATA = "GET_SPACES_DATA";

export const GET_CURRENT_PLAN = "GET_CURRENT_PLAN";
export const SET_CURRENT_PLAN = "SET_CURRENT_PLAN";

export const GET_STRIPE_PLANS = "GET_STRIPE_PLANS";
export const SET_STRIPE_PLANS = "SET_STRIPE_PLANS";

export const CREATE_STRIPE_SESSION = "CREATE_STRIPE_SESSION";
export const SET_STRIPE_SESSION = "SET_STRIPE_SESSION";

export const GET_STRIPE_TRANSACTION_STATUS = "GET_STRIPE_TRANSACTION_STATUS";
export const SET_STRIPE_TRANSACTION_STATUS = "SET_STRIPE_TRANSACTION_STATUS";

export const GET_REPORTS = "GET_REPORTS";
export const SET_REPORTS = "SET_REPORTS";

export const SET_MARKETPLACE_DATA = "SET_MARKETPLACE_DATA";
export const GET_MARKETPLACE_DATA = "GET_MARKETPLACE_DATA";
export const SET_MARKETPLACE_DATA_LOADING = "SET_MARKETPLACE_DATA_LOADING";
