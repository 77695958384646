import {
  SET_ACTIVE_TAB,
  SET_DRAWER,
  OPEN_ACTIVITY_DRAWER,
  CLOSE_ACTIVITY_DRAWER,
  OPEN_CONTEXT_MENU_DRAWER,
  CLOSE_CONTEXT_MENU_DRAWER,
} from "../actions/types";

const initialState = {
  drawerOpen: true,
  activeTab: "users",
  activityDrawerOpen: false,
  notificationMobileDrawerOpen: false,
  drawerOption: null,
  allWorkspaces: null,
  card: null,
  paginatedNotifications: null,
  isOwner: false,
};

export default function drawerReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DRAWER:
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.params,
      };

    case OPEN_ACTIVITY_DRAWER:
      return {
        ...state,
        activityDrawerOpen: true,
      };

    case CLOSE_ACTIVITY_DRAWER:
      return {
        ...state,
        activityDrawerOpen: false,
      };

    case OPEN_CONTEXT_MENU_DRAWER:
      return {
        ...state,
        drawerOption: action.params?.option,
        card: action.params?.card,
        isOwner: action.params?.isOwner,
        allWorkspaces: action.params?.allWorkspaces,
        combinedSites: action.params?.combinedSites,
        paginatedNotifications: action.params?.paginatedNotifications,
        notificationMobileDrawerOpen: true,
      };

    case CLOSE_CONTEXT_MENU_DRAWER:
      return {
        ...state,
        drawerOption: null,
        notificationMobileDrawerOpen: false,
      };

    default:
      return state;
  }
}
