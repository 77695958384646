import { put } from "redux-saga/effects";
import axios from "axios";
import {
  CONFIRM_SHARE_KEY_LOADING,
  GET_ALL_ACCESS_DASHBOARDS,
  GET_ALL_DASHBOARDS,
  HIDE_NEW_DASHBOARD_MODAL,
  SET_ALL_ACCESS_DASHBOARDS,
  SET_ALL_CARDS,
  SET_ALL_DASHBOARDS,
  SET_ALL_VIEW_CARDS,
  SET_SHARE_KEY,
  SHARE_KEY_LOADING,
  SHOW_TOAST,
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* createNewDashboardSaga(params) {
  try {
    let createNewDashboardResponse = yield axios.post(
      `${env.baseURLDev}/dashboard/create`,
      params.params,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (createNewDashboardResponse.status === 201) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Dashboard created.", toastType: 1 },
      });
      setTimeout(() => (window.location = `/dashboards`), 1000);
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* updateDashboardNameDescriptionSaga(params) {
  try {
    let createNewDashboardResponse = yield axios.post(
      `${env.baseURLDev}/dashboard/update-name`,
      params.params,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (createNewDashboardResponse.status === 200) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Details updated.", toastType: 1 },
      });
      // setTimeout(() => window.location.reload(true), 1000);
      yield put({ type: GET_ALL_DASHBOARDS });
      yield put({ type: GET_ALL_ACCESS_DASHBOARDS });
      yield put({ type: HIDE_NEW_DASHBOARD_MODAL });
      // window.location.reload(true);
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* duplicateThisDashboardSaga(params) {
  try {
    let createDuplicateDashboardResponse = yield axios.post(
      `${env.baseURLDev}/dashboard/create-duplicate`,
      params.params,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (createDuplicateDashboardResponse.status === 201) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Dashboard duplicate created.", toastType: 1 },
      });
      // setTimeout(() => window.location = `/dashboards`, 1000);
      yield put({ type: GET_ALL_DASHBOARDS });
      yield put({ type: GET_ALL_ACCESS_DASHBOARDS });
      yield put({ type: HIDE_NEW_DASHBOARD_MODAL });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* moveToTrashSaga(params) {
  try {
    let moveToTrashResponse = yield axios.post(
      `${env.baseURLDev}/dashboard/move-to-trash`,
      params.params,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (moveToTrashResponse.status === 200) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Dashboard moved to trash.", toastType: 1 },
      });
      setTimeout(() => (window.location = `/dashboards`), 1000);
      // yield put({ type: GET_ALL_DASHBOARDS });
      // yield put({ type: GET_ALL_ACCESS_DASHBOARDS });
      // yield put({type: HIDE_NEW_DASHBOARD_MODAL});
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* updateViewersSaga(params) {
  try {
    let moveToTrashResponse = yield axios.post(
      `${env.baseURLDev}/dashboard/update-viewers`,
      params.params,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (moveToTrashResponse.status === 200) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Dashboard viewers updated.", toastType: 1 },
      });
      // setTimeout(() => window.location.reload(), 1000);
      yield put({ type: GET_ALL_DASHBOARDS });
      yield put({ type: GET_ALL_ACCESS_DASHBOARDS });
      yield put({ type: HIDE_NEW_DASHBOARD_MODAL });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* getShareKeySaga(params) {
  try {
    yield put({
      type: SHARE_KEY_LOADING,
      data: true,
    });
    let getShareKeyResponse = yield axios.get(
      `${env.baseURLDev}/dashboard/get-share-key?slug=${params.params.slug}`,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (getShareKeyResponse.status === 200) {
      yield put({ type: SET_SHARE_KEY, data: getShareKeyResponse.data.data });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
    yield put({
      type: SHARE_KEY_LOADING,
      data: false,
    });
  }
}

export function* confirmShareSaga(params) {
  try {
    yield put({
      type: CONFIRM_SHARE_KEY_LOADING,
      data: { data: null, loading: true },
    });
    let confirmShareResponse = yield axios.post(
      `${env.baseURLDev}/dashboard/confirm-share`,
      params.params,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (confirmShareResponse.status === 201) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Share link created for dashboard.", toastType: 1 },
      });
      yield put({
        type: CONFIRM_SHARE_KEY_LOADING,
        data: { data: "Share link created for dashboard", loading: false },
      });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
    yield put({
      type: CONFIRM_SHARE_KEY_LOADING,
      data: false,
    });
    yield put({
      type: CONFIRM_SHARE_KEY_LOADING,
      data: { data: null, loading: false },
    });
  }
}

export function* getAllDashboardsSaga() {
  try {
    let getAllDashboardsResponse = yield axios.get(
      `${env.baseURLDev}/dashboard/get-all`,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (getAllDashboardsResponse.status === 200) {
      yield put({
        type: SET_ALL_DASHBOARDS,
        data: getAllDashboardsResponse.data,
      });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* getAllAccessDashboardsSaga() {
  try {
    let getAllAccessDashboardsResponse = yield axios.get(
      `${env.baseURLDev}/dashboard/get-all-access`,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (getAllAccessDashboardsResponse.status === 200) {
      yield put({
        type: SET_ALL_ACCESS_DASHBOARDS,
        data: getAllAccessDashboardsResponse.data,
      });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* getAllCardsSaga(params) {
  try {
    let getAllCardsResponse = yield axios.get(
      `${env.baseURLDev}/dashboard/get-all-cards?slug=${params.params.slug}`,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (getAllCardsResponse.status === 200) {
      yield put({ type: SET_ALL_CARDS, data: getAllCardsResponse.data.data });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* getAllViewCardsSaga(params) {
  try {
    let getAllViewCardsResponse = yield axios.get(
      `${env.baseURLDev}/dashboard/get-all-view-cards?token=${params.params.token}`,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (getAllViewCardsResponse.status === 200) {
      yield put({
        type: SET_ALL_VIEW_CARDS,
        data: getAllViewCardsResponse.data.data,
      });
    }
  } catch (e) {
    if (e.message.includes("404")) console.log(e);
  }
}

export function* addCardToBoardSaga(params) {
  try {
    let data = {};
    data.dashboardSlug = params.params.dashboardSlug;
    data.selectedCard = params.params.selectedCard;
    data.selectedKPI = params.params.selectedKPI?.value;
    data.selectedTrend = params.params.selectedTrend?.value;
    data.selectedChart = params.params.selectedChart?.value;
    data.workspaceSlug = params.params.selectedWorkspace?.slug;
    data.accManHours =
      params.params.accManHours === ""
        ? null
        : Number(params.params.accManHours);
    data.monthOpt = params.params.monthOpt?.value;

    let addCardToBoardResponse = yield axios.post(
      `${env.baseURLDev}/dashboard/add-card-to-board`,
      data,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (addCardToBoardResponse.status === 201) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Card added to dashboard.", toastType: 1 },
      });
      setTimeout(
        () =>
          (window.location = `/dashboard?slug=${params.params.dashboardSlug}`),
        1000
      );
    }
  } catch (e) {
    if (e.message.includes("400")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message:
            "Card already added to dashboard, please remove it before adding again.",
          toastType: 0,
        },
      });
    } else {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}
