import { put } from "redux-saga/effects";
import axios from "axios";
import {
  SHOW_TOAST,
  SET_TASKS,
  CREATE_NEW_TASK_SUCCESS,
  CREATE_NEW_TASK_FAILURE,
  HIDE_LOADER,
  SHOW_LOADER
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* createNewTaskSaga(params) {
  try {
    yield put({ type: SHOW_LOADER });
    let requestBody = { ...params.params };
    if (requestBody.hasOwnProperty('onSuccess')) {
      delete requestBody.onSuccess;
    }
    let createNewTaskResponse = yield axios.post(
      `${env.newBaseURLDev}/workspaces/action/`,
      requestBody,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (createNewTaskResponse.status === 200 || createNewTaskResponse.status === 201) {
      yield put({ type: CREATE_NEW_TASK_SUCCESS });
      yield put({
        type: SHOW_TOAST,
        data: { message: "Task created.", toastType: 1 },
      });
      yield put({ type: HIDE_LOADER });
      if(params.params.onSuccess){
        params.params.onSuccess(createNewTaskResponse.data);
      }
    }
  } catch {
    yield put({ type: HIDE_LOADER });
    yield put({ type: CREATE_NEW_TASK_FAILURE });
  }
}

export function* getTasksSaga(params) {
  // const board = params?.params?.board;
  try {
    let getTasksResponse = yield axios.get(
      `${env.newBaseURLDev}/workspaces/boards/get_board_display/?board_id=${params?.params?.board_id}`,
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (getTasksResponse.status === 200) {
      yield put({
        type: SET_TASKS,
        data: getTasksResponse.data,
      });
    }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}