import axios from "axios";
import { put, call } from "redux-saga/effects";
import {
  GET_ISSUE_TYPES,
  // CREATE_NEW_ISSUE,
  SHOW_TOAST,
  HIDE_NEW_ISSUE_MODAL,
  SET_ISSUE_TYPES,
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

// Saga for creating a new issue type
export function* createNewIssueTypeSaga(action) {
  console.log("Create New Issue Type Saga called");

  try {
    const createNewIssueResponse = yield call(
      axios.post,
      `${env.newBaseURLDev}/workspaces/board_meta/create_action_types/`,
      action.params,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `${localStorage.getItem("new_token")}`,
        },
      }
    );

    if (createNewIssueResponse.status === 200) {
      yield put({
        type: GET_ISSUE_TYPES,
        params: { site: action.params.site },
      });
      yield put({
        type: SHOW_TOAST,
        data: { message: "Issue created successfully.", toastType: 1 },
      });
      yield put({ type: HIDE_NEW_ISSUE_MODAL });
    }
  } catch (e) {
    console.error("Error creating new issue type:", e);
    const errorMessage =
      e.response?.status === 400
        ? "Issue already created for this workspace."
        : "Something went wrong, please try again later.";
        
    yield put({
      type: SHOW_TOAST,
      data: {
        message: errorMessage,
        toastType: 0,
      },
    });
  }
}




export function* getIssueTypesSaga(action) {
  try {
    const boardId = action.params.board_id || JSON.parse(localStorage.getItem("new_board")).id;

    const getIssueTypesResponse = yield call(
      axios.get,
      `${env.newBaseURLDev}/workspaces/board_meta/get_all_board_entity/?board_id=${boardId}`,
      {
        headers: {
          authorization: `${localStorage.getItem("new_token")}`,
        },
      }
    );

    if (getIssueTypesResponse.status === 200) {
      yield put({
        type: SET_ISSUE_TYPES,
        data: getIssueTypesResponse.data.data,
      });
    }
  } catch (e) {
    console.error("Error fetching issue types:", e);
  }
}

// export function archiveIssueTypeSaga(params) {
//   console.log("Archive Issue Type Saga called");

//   // try {
//   //   let createNewIssueResponse = yield axios.post(
//   //     `${env.baseURLDev}/issue/archive-issue`,
//   //     params.params,
//   //     {
//   //       headers: {
//   //         authorization: `${localStorage.getItem("token")}`,
//   //       },
//   //     }
//   //   );
//   //   if (createNewIssueResponse.status === 200) {
//   //     yield put({
//   //       type: GET_ISSUE_TYPES,
//   //       params: { site: params.params.site },
//   //     });
//   //     yield put({
//   //       type: SHOW_TOAST,
//   //       data: { message: "Issue deleted successfully.", toastType: 1 },
//   //     });
//   //   }
//   // } catch (e) {
//   //   if (e.message.includes("400")) {
//   //     yield put({
//   //       type: SHOW_TOAST,
//   //       data: {
//   //         message: "Issue already created for this workspace.",
//   //         toastType: 0,
//   //       },
//   //     });
//   //   } else {
//   //     yield put({
//   //       type: SHOW_TOAST,
//   //       data: {
//   //         message: "Something went wrong, please try again later.",
//   //         toastType: 0,
//   //       },
//   //     });
//   //   }
//   // }
// }

