import { put } from "redux-saga/effects";
import axios from "axios";
import { SET_REPORTS, SHOW_TOAST } from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* getReportsSaga(params) {
  try {
    const res = yield axios.get(
      `${env.newBaseURLDev}/reports/report/?${params.params}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    yield put({
      type: SET_REPORTS,
      payload: res.data.response || [],
    });
  } catch (error) {
    yield put({
      type: SHOW_TOAST,
      payload: true,
    });
  }
}
