import {
  SIGN_UP,
  LOG_IN,
  SHOW_TOAST,
  HIDE_TOAST,
  ADD_NEW_SITE,
  ADD_NEW_BOARD,
  CREATE_NEW_TASK,
  ADD_USER_TO_PROJECT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_AVATAR,
} from "../actions/types";

const initialState = {
  message: "",
  toastType: null,
  toastId: null,
  autoHide: true,
};

export default function toastReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER_TO_PROJECT:
      return {
        ...state,
        message: "",
        toastType: null,
      };

    case SIGN_UP:
      return {
        ...state,
        message: "",
        toastType: null,
      };

    case LOG_IN:
      return {
        ...state,
        message: "",
        toastType: null,
      };

    case FORGOT_PASSWORD:
      return {
        ...state,
        message: "",
        toastType: null,
      };

    case RESET_PASSWORD:
      return {
        ...state,
        message: "",
        toastType: null,
      };

    case ADD_NEW_SITE:
      return {
        ...state,
        message: "",
        toastType: null,
      };

    case ADD_NEW_BOARD:
      return {
        ...state,
        message: "",
        toastType: null,
      };

    case SHOW_TOAST:
      return {
        ...state,
        message: action.data.message,
        toastType: action.data.toastType,
        autoHide: action.data.autoHide ?? true,
        toastId: action.data.toastId,
      };

    case HIDE_TOAST:
      return {
        ...state,
        message: "",
        toastType: null,
        toastId: null,
      };

      case CREATE_NEW_TASK:
        return {
          ...state,
          message: "",
          toastType: null,
        };

    case UPDATE_AVATAR:
      return {
        ...state,
        message: "",
        toastType: null,
      };

    default:
      return state;
  }
}
