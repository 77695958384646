import { SET_ISSUE_TYPES, GET_ISSUE_TYPES } from "../actions/types";

const initialState = {
  issues: [],
  isIssueloading: false,
};

export default function issueReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ISSUE_TYPES:
      return {
        ...state,
        isIssueloading: true,
      };

    case SET_ISSUE_TYPES:
      return {
        ...state,
        issues: action.data,
        isIssueloading: false,
      };

    default:
      return state;
  }
}
