import { SET_MARKETPLACE_DATA, SET_MARKETPLACE_DATA_LOADING } from "../actions/types";

const initialState = {
    marketplaceData: null,
    marketplaceDataLoading: false,
    agentHistory: null,
    addedAgents: null,
};

export default function marketplaceReducer(state = initialState, action) {
  switch (action.type) {

    case SET_MARKETPLACE_DATA:
      return {
        ...state,
        marketplaceData: action.payload,
      };

    case SET_MARKETPLACE_DATA_LOADING:
      return {
        ...state,
        marketplaceDataLoading: action.payload,
      };
    case "SET_AGENT_HISTORY":
      return {
        ...state,
        agentHistory: action.payload,
      };
    case "SET_ADDED_AGENTS":
      return {
        ...state,
        addedAgents: action.payload,
      };
    default:
      return state;
  }
}
