import { GET_REPORTS, SET_REPORTS } from "../actions/types";

const initialState = {
  reportsData: [],
  reportsLoading: false,
};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORTS:
      return { ...state, reportsLoading: true };

    case SET_REPORTS:
      return {
        ...state,
        reportsData: action.payload,
        reportsLoading: false,
      };

    default:
      return state;
  }
}
