const initialState = {
    workspaceDetails: null,
};

export default function workspaceReducer(state = initialState, action) {
  switch (action.type) {

    case "UPDATE_WORKSPACE_DETAILS":
      return {
        ...state,
        workspaceDetails: action.details,
      };

    default:
      return state
  }
}
