import {
  SIGN_UP,
  LOG_IN,
  AUTHENTICATION_FAILED,
  GET_USERS_FOR_SITES,
  SET_USERS_FOR_SITES,
} from "../actions/types";

const initialState = {
  signingUp: false,
  loggingIn: false,
  usersForSitesLoading: true,
  usersForSites: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_UP:
      return {
        ...state,
        signingUp: true,
      };

    case LOG_IN:
      return {
        ...state,
        loggingIn: true,
      };

    case AUTHENTICATION_FAILED:
      return {
        ...state,
        signingUp: false,
        loggingIn: false,
      };

    case GET_USERS_FOR_SITES:
      return {
        ...state,
        usersForSitesLoading: true,
      };

    case SET_USERS_FOR_SITES:
      return {
        ...state,
        usersForSitesLoading: false,
        usersForSites: action.data,
      };
    default:
      return state;
  }
}
